<template>
  <div class="container" style="min-height: 500px;">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-left my-4 page-heading">Need Help with Interviews?</h1>
      </div>
      <div class="col-sm-12 text-justify mb-5">
        <article class="fs-16">
          <p>Interviews can be a minefield. “Behavioural competency questions”, “situational or hypothetical interviews”, “what’s your greatest weakness?”; what do these things even mean!?</p>
          <p>Whether you’re seeking graduate interview coaching, public service interview coaching or to land a senior role, I have you covered. I have over 10 years experience working with private and enterprise clients across a variety of industries and will guide you through the steps in a format that helps clarify everything you need to know. </p>
          <p>Structured interview packages are a starting point only, everything can be customised to make sure you get what you need.</p>
          <p>Service is available remotely so all you need is an internet connection and you’re good to go. Check out the testimonials on the home page and see here for further details on the package.</p>
          <p>Get in touch to find out more! <router-link class="font-weight-bold" to="/contact">Contact Now</router-link> </p>
        </article>
      </div>
    </div>
    <!-- Testimonials -->
      <div class="row">
        <div class="col-sm-12">
          <h2 class="mt-5">Testimonials</h2>              
        </div>
      </div>
      <div id="carouselExampleIndicators" class="carousel slide my-5" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item" :class="{'active': keys === 0}" v-for="(values, keys) in testimonial" v-bind:key="keys">
            <div class="row">
              <div class="col-sm-6 mb-3" v-for="(value, key) in values" v-bind:key="key">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title"><img src="@/assets/images/quote-left.png"></h4>
                    <div class="template-demo" style="min-height: 220px">
                      <p>{{ value.Testimonial }} - <b>{{ value.Name }}, {{ value.Job }}</b></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ol class="carousel-indicators mt-3">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
      </div>
    <!-- Testimonials -->
  </div>
</template>
<style scoped>
.carousel-indicators { position: relative; }
.carousel-indicators li { background-color: #333; }
.carousel-inner .active { border: none; }
/* Testimonial */
.card { position: relative; display: flex; flex-direction: column; min-width: 0; word-wrap: break-word; background-color: #fff; background-clip: border-box; border: 1px solid #d2d2dc; border-radius: 5px; }
.card .card-body { padding: 1rem 1rem; }
.card-body { flex: 1 1 auto; padding: 1.25rem; }
</style>
<script>
export default {
  data () {
    return {
      testimonial: [
        [
          {
            'Testimonial': '“Thank you for your excellent guidance during my interview preparation, it definitely gave me extra confidence going into the last round and helped me get the job! The advice was well structured, easy to assimilate and I didn’t feel bombarded with too much information. Thanks again!”',
            'Name': 'Charlie',
            'Job': 'Gaming'
          },
          {
            'Testimonial': '“I just wanted to let you know that I\'ve been offered and have accepted the job! I wanted to thank you for your help and guidance in our interview session. You provided me with great advice which I was able to build on in preparing for the interviews so that I could approach them with confidence. As I hadn\'t done an interview since my college days your expert input was invaluable! Many thanks again.”',
            'Name': 'Niamh',
            'Job': 'Legal'
          }
        ], 
        [
          {
            'Testimonial': '“Many thanks for your assistance and advice it was extremely useful and beneficial. I used the advice you gave me to lead the interview off strongly and it worked! I asked the manager for some further feedback and he said that the interview had done really well and so he didn\'t have anything critical to say whatsoever. Thanks again. I found your advice so helpful and you delivered it in a very constructive, digestible way that was easy to take on board and use.”',
            'Name': 'John',
            'Job': 'Banking'
          },
          {
            'Testimonial': '“Hi Susan, on the presentation, I got the top score of all candidates which I was delighted with! I really benefited from your excellent assistance and advice, so many thanks again.“',
            'Name': 'Anne',
            'Job': 'Finance'
          }
        ],
        [
          {
            'Testimonial': '“Susan - Thanks for your help!  I was offered the promotion which I have accepted!“',
            'Name': 'Stephen',
            'Job': 'Government'
          },
        ]
      ]
    }
  }
}
</script>