<template>
  <header>
    <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <router-link class="navbar-brand" to="/">
          <Logo />
        </router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      
        <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <router-link tag="li" class="nav-item" exact-active-class="active" to="/">
              <a class="nav-link">Home</a>
            </router-link>
            <router-link tag="li" class="nav-item" exact-active-class="active" to="/about">
              <a class="nav-link">About</a>
            </router-link>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Career Seekers
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link class="dropdown-item" tag="a" to="interview-coaching">Interview Coaching</router-link>
                <router-link class="dropdown-item" tag="a" to="career-coaching">Career Coaching</router-link>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Hiring
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link class="dropdown-item" tag="a" to="psychometric-testing">Psychometric Testing</router-link>
              </div>
            </li>
            <router-link tag="li" class="nav-item" exact-active-class="active" to="/contact">
              <a class="nav-link">Contact</a>
            </router-link>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import Logo from './Logo'
export default {
  components: {
    'Logo': Logo
  }
}
</script>