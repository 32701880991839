<template>
  <div>
    <Header/>
    <slot/>
    <Footer/>
  </div>
</template>
<style>
  
</style>
<script>
  import Header from '@/components/common/Header.vue'
  import Footer from '@/components/common/Footer.vue'
  
  export default {
    components: {
      'Header': Header,
      'Footer': Footer
    }
  }
</script>