<template>
  <div>
    <!-- Banner -->
      <div class="container-fluid banner-background">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 mt-5" style="min-height: 400px;">
              <h2 class="px-2 text-center" style="margin-top: 100px;color: #000;">Discover your story</h2>
            </div>
          </div>   
        </div>     
      </div>
    <!-- Banner -->
    <div class="container">
      <div class="container">
        <!-- Services -->
          <div class="row" style="min-height: 400px;">
            <div class="col-sm-12">
              <h2 class="mt-5">Services</h2>              
            </div>
            <div class="row my-5">
              <div class="col-sm-4 mb-5">
                <router-link to="interview-coaching" class="mb-3">
                  <img src="@/assets/images/interview.svg" alt="Interview" title="Interview" class="img-fluid" style="width: 50%">
                  <h6 class="text-center mt-5">Interview Coaching</h6>
                </router-link>
              </div>
              <!-- <div class="col-sm-3 mb-5">
                <router-link to="cv-writing">
                  <img src="@/assets/images/update-resume.svg" alt="CV Preparation" title="CV Preparation" class="img-fluid" style="width: 50%">
                  <h6 class="text-center mt-3">CV Writing</h6>
                </router-link>
              </div> -->
              <div class="col-sm-4 mb-5">
                <router-link to="career-coaching" class="mb-3">
                  <img src="@/assets/images/process.svg" alt="Career Coaching" title="Career Coaching" class="img-fluid" style="width: 42%">
                  <h6 class="text-center mt-3">Career Coaching</h6>
                </router-link>
              </div>
              <div class="col-sm-4 mb-5">
                <router-link to="psychometric-testing" class="mb-3">
                  <img src="@/assets/images/exams.svg" alt="Psychometric Testing" title="Psychometric Testing" class="img-fluid" style="width: 42%">
                  <h6 class="text-center mt-5">Psychometric Testing</h6>
                </router-link>
              </div>
            </div>
            
          </div>
        <!-- Services -->
      </div>
    </div>
    <div class="container-fluid" style="background-color: #E0F1EA;">
      <!-- Short Intro about Susan Kealy -->
      <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-sm-8 my-5">
            <h2 class="text-left page-heading">About Susan</h2>
            <p class="text-justify mt-5 fs-16">I am a Chartered Psychologist who has been working in the area of career management, coaching and psychometric testing for well over a decade. I love to help people get more clarity about their career and life goals and I work to equip my clients with the skills they need to find the right jobs for them. I also help employers to learn more about the abilities, values, motivations and personalities of their candidates so that the right people can be picked for the right jobs.</p>
            <!-- <p class="text-justify fs-16">
              I am a work and organisational psychologist and have been specialising in the world of job applications, psychometric testing, assessment, revenue writing and interview preparation for the past 10 years.
            </p> -->
            <div class="text-left">
              <router-link to="about" class="btn btn-primary mt-3">Know more</router-link>
            </div>
          </div>
          <div class="col-sm-4">
            <img src="@/assets/images/susan-kealy-side-pose.jpg" alt="Susan Kealy" title="Susan Kealy" class="img-fluid my-3">
          </div>
        </div>
      </div>
      <!-- Short Intro about Susan Kealy -->
    </div>
    <div class="container">
      <!-- Testimonials -->
        <div class="row">
          <div class="col-sm-12">
            <h2 class="mt-5">Testimonials</h2>              
          </div>
        </div>
        <div id="carouselExampleIndicators" class="carousel slide my-5" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item" :class="{'active': keys === 0}" v-for="(values, keys) in testimonial" v-bind:key="keys">
              <div class="row">
                <div class="col-sm-6 mb-3" v-for="(value, key) in values" v-bind:key="key">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title"><img src="@/assets/images/quote-left.png"></h4>
                      <div class="template-demo" style="min-height: 190px">
                        <p>{{ value.Testimonial }} - <b>{{ value.Name }}, {{ value.Job }}</b></p>
                      </div>
                      <!-- <hr>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="profile">
                            <h4 class="cust-name">{{ value.Name }}</h4>
                            <p class="cust-profession text-center">{{ value.Job }}</p>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ol class="carousel-indicators mt-3">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
        </div>
      <!-- Testimonials -->
    </div>
  </div>
</template>
<style scoped>
.banner-background { background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.3)), url('../assets/images/banner-3.png'); background-size: cover; background-repeat: no-repeat;}
/* .banner-background { background-color: #F2F2F2; } */
.carousel-indicators { position: relative; }
.carousel-indicators li { background-color: #333; }
.carousel-inner .active { border: none; }
/* Testimonial */
.padding { padding: 5rem; }
.card { position: relative; display: flex; flex-direction: column; min-width: 0; word-wrap: break-word; background-color: #fff; background-clip: border-box; border: 1px solid #d2d2dc; border-radius: 5px; }
.card .card-body { padding: 1rem 1rem; }
.card-body { flex: 1 1 auto; padding: 1.25rem; }
p { font-size: 0.9rem; margin-bottom: .5rem; line-height: 1.5rem; }
/* h4 { line-height: .2 !important; } */
.profile { margin-top: 16px; margin-left: 11px; }
.profile-pic { width: 58px; }
.cust-name { font-size: 18px; }
.cust-profession { font-size: 10px; }
.items { width: 90%; margin: 0px auto; margin-top: 30px; }
.slick-slide { margin: 10px; }
</style>
<script>
export default {
  data () {
    return {
      testimonial: [
        [
          {
            'Testimonial': '“Thank you for your excellent guidance during my interview preparation, it definitely gave me extra confidence going into the last round and helped me get the job! The advice was well structured, easy to assimilate and I didn’t feel bombarded with too much information. Thanks again!”',
            'Name': 'Charlie',
            'Job': 'Gaming'
          }, 
          {
            'Testimonial': '“I just wanted to let you know that I\'ve been offered and have accepted the job! I wanted to thank you for your help and guidance in our interview session. You provided me with great advice which I was able to build on in preparing for the interviews so that I could approach them with confidence. As I hadn\'t done an interview since my college days your expert input was invaluable! Many thanks again.”',
            'Name': 'Niamh',
            'Job': 'Legal'
          }
        ], 
        [
          {
            'Testimonial': '“Many thanks for your assistance and advice it was extremely useful and beneficial. I used the advice you gave me to lead the interview off strongly and it worked! I asked the manager for some further feedback and he said that the interview had done really well and so he didn\'t have anything critical to say whatsoever. Thanks again. I found your advice so helpful and you delivered it in a very constructive, digestible way that was easy to take on board and use.”',
            'Name': 'John',
            'Job': 'Banking'
          }, 
          {
            'Testimonial': '“Hi Susan, on the presentation, I got the top score of all candidates which I was delighted with! I really benefited from your excellent assistance and advice, so many thanks again.“',
            'Name': 'Anne',
            'Job': 'Finance'
          }
        ],
        [
          {
            'Testimonial': '“Susan - Thanks for your help!  I was offered the promotion which I have accepted!“',
            'Name': 'Stephen',
            'Job': 'Government'
          }, 
          {
            'Testimonial': '“I would like to thank you again for all your help over the past months. I got so much more out of the process than simple coaching. That is down to your skills and professionalism. It was an absolute pleasure.”',
            'Name': 'Colm',
            'Job': 'Finance'
          }
        ]
      ]
    }
  }
}
</script>
