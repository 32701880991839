<template>
  <div>
    <span class="logo-first">Career</span>
    <span class="logo-second" :style="{'color': logoSecond}">Craft.ie</span>
    <span class="d-block tagline" :style="{'color': tagLine}">Love your Work</span>
  </div>
</template>
<script>
export default {
  props: {
    logoSecond: {
      type: String
    },
    tagLine: {
      type: String
    }
  }
}
</script>