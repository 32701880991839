import { Bus } from './main'
import Axios from 'axios'
const utility = {
  /**
   * @author Sanket Utekar
   * @abstract Show/hide loader
   * @since 17-05-2020
   */
  showLoader (loader) {
    Bus.$emit('loader', loader) // Emit loader event
  },
  /**
   * @author Sanket Utekar
   * @abstract Show alert modal
   * @since 07-10-2020
   */
  showAlertModal (message, action) {
    Bus.$emit('alertModal', {
      Message: message,
      Action: action
    }) // Emit alert event
  },
  /**
   * @author Sanket Utekar
   * @abstract Convert YYYY-mm-dd HH:mm:ii format to dd-mm-YYYY HH:mm
   * @since 17-05-2020
   */
  formatDate (dateString, formatTime = 'Yes') {
    if (dateString != 'None') {
      let date = new Date(dateString.replace(/-/g, '/')) // Replace - to /
      let strDay = date.getDate()
      strDay = (strDay < 10 ? '0' + strDay : strDay)
      let strMonth = (date.getMonth() + 1)
      strMonth = (strMonth < 10 ? '0' + strMonth : strMonth)
      let strYear = date.getFullYear()
      let hours = date.getHours()
      hours = (hours < 10 ? '0' + hours : hours)
      let minutes = date.getMinutes()
      minutes = minutes < 10 ? '0' + minutes : minutes
      let strTime = hours + ':' + minutes
      if (formatTime == 'Yes') {
        return strDay + '-' + strMonth + '-' + strYear + ' ' + strTime
      } else {
        return strDay + '-' + strMonth + '-' + strYear
      }
    } else {
      return '00-00-0000 00:00'
    }
  },
  /**
   * @author Sanket Utekar
   * @abstract Remove all special characters from the string
   * @since 17-05-2020
   */
  formatString (stringToConvert) {
    var convertedString = stringToConvert.trim().replace(/[^a-zA-Z0-9]/g,' ').replace(/\b[a-z]/g, (x) => x.toUpperCase())
    return convertedString
  },
  /**
   * @author Sanket Utekar
   * @abstract Get unique transaction id for service transaction
   * @since 17-05-2020
   */
  generateTransactionID () {
    return localStorage.getItem('userID') + localStorage.getItem('roleID') + Math.random().toString(36).substring(2) + Date.now().toString(36)
  },
  /**
   * @author Sanket Utekar
   * @abstract Convert a string to base64encoding
   * @since 17-05-2020
   */
  base64Encode (stringToConvert) {
    var convertedString = btoa(stringToConvert)
    return convertedString
  },
  /**
   * @author Sanket Utekar
   * @abstract Replace all special characters with hyphen
   * @since 17-05-2020
   */
  createURL (stringToConvert) {
    return stringToConvert.toLowerCase().replace(/[^A-Z0-9]+/ig, '-') // replace all special characters with hyphen
  },
  /**
   * @author Sanket Utekar
   * @abstract Capture the run time javascript errors
   * @since 17-05-2020
   */
  saveErrorLog (errorClass, errorDescription, thisObj) {
    Axios.post('error-tracking', {
      error_in_class: errorClass,
      error_desc: errorDescription,
      error_from: 'Vue.js'
    }).then(function () {
      // Redirect user to login
      thisObj.$router.push({ path: '/' })
    })
  },
  /**
   * @author Sanket Utekar
   * @abstract Detect user's browser
   * @since 17-05-2020
   */
  detectBrowser () {
    var userAgent = window.navigator.userAgent
    var edgeRegex = /edg/i
    var oprRegex = /opr/i
    var chromeRegex = /chrome/i
    var tridentRegex = /trident/i
    var firefoxRegex = /firefox/i
    var safariRegex = /safari/i

    switch (true) {
      case edgeRegex.test(userAgent): 
        return 'edge'
      case oprRegex.test(userAgent) && (!!window.opr || !!window.opera): 
        return 'opera'
      case chromeRegex.test(userAgent) && !!window.chrome: 
        return 'chrome'
      case tridentRegex.test(userAgent): 
        return 'ie'
      case firefoxRegex.test(userAgent): 
        return 'firefox'
      case safariRegex.test(userAgent): 
        return 'safari'
      default: 
        return 'other'
    }
  },
  
  /**
   * @author Sanket Utekar
   * @abstract Generate calendar 
   * 1. Get current date
   * 2. Create day, month, and year variables
   * 3. Get total number of days in a month
   * 4. Get total number of weeks in a month
   * 5. Get the day of the week when the first day begins
   * @since 17-05-2020
   */
  generateCalendar (currentYear, currentMonth, availableDates) {
    // window.console.log(availableDates[4]['time_id'])
    const totalNumberOfDays = this.getTotalNumberOfDays(currentYear, currentMonth)
    let totalNumberOfWeeks = 6 // Considering 6 weeks by default
    // const totalNumberOfWeeks = this.getTotalNumberOfWeeks(currentYear, currentMonth)
    const firstDayOfWeek = this.getDayOfTheWeek(currentYear, currentMonth)

    let calendarData = {} // Array containing all the dates
    let weeklyDates = []
    let dateCounter = 1 // Starting date of the month
    let firstDayPushed = 'No'
    let keyExist = false

    for (let i = 0; i < totalNumberOfWeeks; i++) { // Loop for the total number of weeks for the month
      weeklyDates = [] // Initialise the array
      if (dateCounter > totalNumberOfDays) { // Exit the loop when the date counter is equal to total number of days
        break
      }
      for (let j = 0; j < 7; j++) { // Loop for the total days in a week
        if (i == 0) {
          if (availableDates[dateCounter] != undefined) {
            keyExist = true
          } else {
            keyExist = false
          }
          // window.console.log(keyExist)
          if (firstDayOfWeek == j) {
            // weeklyDates.push(dateCounter)
            weeklyDates.push({ 
              'day': dateCounter,
              'fullDate': currentYear + '-' + currentMonth + '-' + (dateCounter < 9 ? '0' + dateCounter: dateCounter),
              'isAvailable': (keyExist ? 'Yes' : 'No')
            })
            dateCounter = dateCounter + 1 // Increment date counter
            firstDayPushed = 'Yes'
          } else if (firstDayPushed == 'Yes') {
            if (availableDates[dateCounter] != undefined) {
              keyExist = true
            } else {
              keyExist = false
            }
            weeklyDates.push({ 
              'day': dateCounter,
              'fullDate': currentYear + '-' + currentMonth + '-' + (dateCounter < 9 ? '0' + dateCounter: dateCounter),
              'isAvailable': (keyExist ? 'Yes' : 'No')
            })
            dateCounter = dateCounter + 1 // Increment date counter
            firstDayPushed = 'Yes'
          } else {
            weeklyDates.push({ 
              'day': 0,
              'fullDate': '',
              'isAvailable': ''
            })
          }
        } else {
          // window.console.log(totalNumberOfDays, dateCounter, totalNumberOfWeeks)
          if (dateCounter <= totalNumberOfDays) {
            if (availableDates[dateCounter] != undefined) {
              keyExist = true
            } else {
              keyExist = false
            }
            weeklyDates.push({ 
              'day': dateCounter,
              'fullDate': currentYear + '-' + currentMonth + '-' + (dateCounter < 9 ? '0' + dateCounter: dateCounter),
              'isAvailable': (keyExist ? 'Yes' : 'No')
            })
            dateCounter = dateCounter + 1 // Increment date counter
          } else {
            weeklyDates.push({ 
              'day': 0,
              'fullDate': '',
              'isAvailable': ''
            })
          }
        }
      }
      calendarData[i] = weeklyDates // Push weekly dates in the final array
    }
    return calendarData
  },
  /**
   * @author Sanket Utekar
   * @abstract Get total number if days in a month
   * @since 17-05-2020
   */
  getTotalNumberOfDays (currentYear, currentMonth) {
    return new Date(currentYear, currentMonth, 0).getDate()
  },
  // getTotalNumberOfWeeks (currentYear, currentMonth) {
  //   const date = new Date(currentYear, currentMonth, 0)
  //   return Math.ceil((date.getDate() + date.getDay()) / 7) // Get total number of weeks in a month
  // },
  /**
   * @author Sanket Utekar
   * @abstract Get the day of the week when the first day begins
   * @since 17-05-2020
   */
  getDayOfTheWeek (currentYear, currentMonth) {
    return new Date(currentYear, (currentMonth - 1), 1).getDay()
  },
  /**
   * @author Sanket Utekar
   * @abstract Get the user's geo-location
   * @since 17-05-2020
   */
  getLocation () {
    var locationDetail
    // Check if the latitude and longitude exists
    if (localStorage.getItem('latitude') === undefined) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          localStorage.setItem('latitude', position.coords.latitude)
          localStorage.setItem('longitude', position.coords.longitude)      
        })
        return locationDetail
      }
    }
  }
}
export default utility