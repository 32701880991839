<template>
  <div class="container accordion_one">
    <div class="row mt-4" style="min-height: 500px;">
      <div class="col">
        <div class="panel-group" id="accordionFourLeft">
          <h1 class="text-left">FAQ</h1>
          <div class="panel panel-default" v-for="(values, keys) in faqs" v-bind:key="keys">
            <div class="panel-heading">
              <h5 class="panel-title"> 
                <a data-toggle="collapse" :href="'#collapseFiveLeft' + keys" aria-expanded="false" class="collapsed"> {{ values.Question }} </a> 
              </h5>
            </div>
            <div :id="'collapseFiveLeft' + keys" class="panel-collapse collapse" aria-expanded="false" role="tablist" style="height: 0px;">
              <div class="panel-body">
                <div class="text-accordion">
                  <p class="text-left">{{ values.Answer }}</p>
                </div>
              </div> <!-- end of panel-body -->
            </div>
          </div> <!-- /.panel-default -->
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.accordion_one .panel-group > .panel-default { border: 1px solid #f1f1f1; }
a:link { text-decoration: none; }
.accordion_one .panel { background-color: transparent; box-shadow: none; border-bottom: 0px solid transparent; border-radius: 0; margin: 0; }
.accordion_one .panel-default { border: 0; }
.accordion-wrap .panel-heading { padding: 0px; border-radius: 0px; }
h4 { font-size: 18px; line-height: 24px; }
.accordion_one .panel .panel-heading a.collapsed { display: block; padding: 12px 30px; border-top: 0px; }
.accordion_one .panel .panel-heading a { display: block; padding: 12px 30px; background: #fff; color: #333333; border-bottom: 1px solid #f1f1f1; }
.accordion-wrap .panel .panel-heading a { font-size: 14px; }
.accordion_one .panel-group .panel-heading+.panel-collapse>.panel-body { border-top: 0; padding-top: 0; padding: 25px 30px 30px 35px; background: #fff; color: #333333; }
.img-accordion { width: 81px; float: left; margin-right: 15px; display: block; }
.accordion_one .panel .panel-heading a.collapsed:after { content: "\2b"; background: #f1f1f1; }
.accordion_one .panel .panel-heading a:after,
.accordion_one .panel .panel-heading a.collapsed:after { font-family: 'FontAwesome'; font-size: 15px; width: 36px; line-height: 48px; text-align: center; background: #F1F1F1; float: left; margin-left: -31px; margin-top: -12px; margin-right: 15px; }
.accordion_one .panel .panel-heading a:after { content: "\2212"; }
.accordion_one .panel .panel-heading a:after,
.accordion_one .panel .panel-heading a.collapsed:after { font-family: 'FontAwesome'; font-size: 15px; width: 36px; height: 48px; line-height: 48px; text-align: center; background: #F1F1F1; float: left; margin-left: -31px; margin-top: -12px; margin-right: 15px; }
.panel-title { text-align: left; }
</style>
<script>
/** @module FAQ **/
import Axios from 'axios'
import Utility from '../utility'
export default {
  data () {
    return {
      faqs: []
    }
  },
  methods: {
    /**
     * @author Sanket Utekar
     * @abstract Get all FAQs
     * @since 04-10-2020
     */
    getFAQS () {
      var thisObj = this
      Utility.showLoader(true) // Show loader
      Axios.get('faq').then(function (response) {
        thisObj.faqs = response.data.Data
        Utility.showLoader(false) // Hide loader
      })
    }
  },
  created () {
    this.getFAQS() // Get FAQs
  }
}
</script>
