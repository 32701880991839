<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-left my-4 page-heading">Reassessing Your Career?</h1>
      </div>
      <div class="col-sm-12 text-justify mb-3">
        <h4>Career Coaching for Adults</h4>
        <article class="fs-16">
          <p>If you are using this time to re-evaluate your career and need some help working out where to go next, then career coaching could be for you. Career transition can be an empowering time but one with many questions and a whole lot of uncertainty. Coaching can help clarify what you want, what’s important to you, what’s practical for you and how to get to where you’re going.</p>
          <p>Service is available remotely so all you need is an internet connection and you’re good to go. Coaching is available with or without psychometric testing – <router-link class="font-weight-bold" to="/contact">reach out</router-link> to know more!</p>
        </article>
      </div>
      <div class="col-sm-12 text-justify mb-5">
        <h4>Career Coaching for School Students</h4>
        <article class="fs-16">
          <p>Making choices about college and/or future careers can be a difficult and stressful time for so many students and parents.</p>
          <p>With thousands of options out there, and limited knowledge of what jobs really involve, or where a student narrowing them down can seem like an impossible task. </p>
          <p>I work with students and parents, often over a series of sessions, to gain a deeper understanding of the unique attributes that make you you. Using my SHINE model, a flexible attitude and a structured process, I offer insights, direction and a path forward. </p>
          <p>Email me for more details - <router-link class="font-weight-bold" to="/contact">reach out</router-link></p>
          <!-- <p>Check out the testimonials on the home page and see here for further details on the package.</p> -->
        </article>
      </div>
    </div>
    <!-- Testimonials -->
      <div class="row">
        <div class="col-sm-12">
          <h2 class="mt-3">Testimonials</h2>              
        </div>
      </div>
      <div id="carouselExampleIndicators" class="carousel slide my-5" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item" :class="{'active': keys === 0}" v-for="(values, keys) in testimonial" v-bind:key="keys">
            <div class="row">
              <div class="col-sm-6 mb-3" v-for="(value, key) in values" v-bind:key="key">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title"><img src="@/assets/images/quote-left.png"></h4>
                    <div class="template-demo" style="min-height: 330px;">
                      <p>{{ value.Testimonial }} - <b>{{ value.Name }} {{ value.Job }}</b></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ol class="carousel-indicators mt-3">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        </ol>
      </div>
    <!-- Testimonials -->
  </div>
</template>
<style scoped>
.carousel-indicators { position: relative; }
.carousel-indicators li { background-color: #333; }
.carousel-inner .active { border: none; }
/* Testimonial */
.padding { padding: 5rem; }
.card { position: relative; display: flex; flex-direction: column; min-width: 0; word-wrap: break-word; background-color: #fff; background-clip: border-box; border: 1px solid #d2d2dc; border-radius: 5px; }
.card .card-body { padding: 1rem 1rem; }
.card-body { flex: 1 1 auto; padding: 1.25rem; }
</style>
<script>
export default {
  data () {
    return {
      testimonial: [
        [
          {
            'Testimonial': '“My daughter worked with Susan to figure out what she really wanted to put on her CAO. Susan got her to think outside the box and they narrowed down options by looking at interests, strengths and abilities with coaching and assessments. What I loved about Susan’s work and approach is that there are multiple meet ups where they analysed options together and then there was time also for research and reflection so the career guidance happens piece by piece . A lot of listening, guidance and research takes place to arrive to a shortlist of possible study and career options. I would consider it a deluxe career guidance package and it certainly did just what it intended, it guided my daughter to the right courses for her”',
            'Name': 'Noreen',
            'Job': '(Parent)'
          }, 
          {
            'Testimonial': '“Susan is not only great at what she does, she’s also a caring and empathetic person. I found the career guidance beneficial as it was very complete and it was helpful how attentive to detail she was when going through the assessments. It gave me a very clear idea of what type of person I am, and I what roles I could be suited for in a future career. The end report was so informative and gave me very good suggestions for possible careers, I liked how she added in a lot of detail about them (eg. salaries, job prospects). She also included many resources and websites that helped me do research about possible jobs/courses. I would definitely recommend Susan”',
            'Name': 'Kate',
            'Job': '(Student)'
          }
        ],
        [
          {
            'Testimonial': '“I would like to thank you again for all your help over the past months. I got so much more out of the process than simple coaching. That is down to your skills and professionalism. It was an absolute pleasure.”',
            'Name': 'Colm',
            'Job': 'Finance'
          }
        ]
      ]
    }
  }
}
</script>