import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuelidate from 'vuelidate'
import Default from './layouts/Default'
import './registerServiceWorker'
import jQuery from 'jquery'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

global.jQuery = jQuery
global .$ = jQuery
Vue.component('default-layout', Default)
Vue.use(Vuelidate) // Inject vuelidate package
Vue.config.productionTip = false
export const Bus = new Vue()

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
