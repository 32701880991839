<template>
  <section id="footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <Logo :logoSecond="'#fff'" :tagLine="'#fff'"></Logo>
        </div>
      </div>
      <div class="row mt-5 text-center text-xs-center text-sm-left text-md-left">
        <div class="col-xs-12 col-sm-4 col-md-4">
          <h5>Quick links</h5>
          <ul class="list-unstyled quick-links">
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/about">About</router-link></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-4">
          <h5>Information</h5>
          <ul class="list-unstyled quick-links">
            <li><router-link to="/interview-coaching">Interview Coaching</router-link></li>
            <li><router-link to="/career-coaching">Career Coaching</router-link></li>
            <li><router-link to="/psychometric-testing">Psychometric Testing</router-link></li>
            <li><router-link to="/contact">Contact</router-link></li>
          </ul>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-6 text-center">
          <p class="h6 text-white">© All Rights Reserved.<a class="ml-1 text-white" target="_blank">CareerCraft.ie</a></p>
        </div>
        <!-- <div class="col-sm-6">
          <p class="h6 text-white text-right">Powered By<a class="ml-1 text-white" href="http://www.yourtechmonks.com/" target="_blank">YourTechMonks</a></p>
        </div> -->
        <hr>
      </div>
      <!-- Loader starts -->
        <Loader></Loader>
      <!-- Loader ends -->
    </div>
  </section>
</template>
<style scoped>
#footer { padding: 40px 0 30px 0px; background: #878787;}
#footer .section-title { text-align: left; margin-bottom: 50px; text-transform: uppercase; }
#footer h5 { padding-left: 10px; border-left: 3px solid #9de3cd; padding-bottom: 6px; margin-bottom: 20px; color:#fff; }
#footer a { color: #fff; text-decoration: none !important; background-color: transparent; -webkit-text-decoration-skip: objects; }
#footer ul.social li { padding: 3px 0; }
#footer ul.social li a i { margin-right: 5px; font-size:25px; -webkit-transition: .5s all ease; -moz-transition: .5s all ease; transition: .5s all ease; }
#footer ul.social li:hover a i { font-size:30px; margin-top:-10px; }
#footer ul.social li a, #footer ul.quick-links li a { color:#fff; }
#footer ul.social li a:hover { color:#fff; }
#footer ul.quick-links li a i { margin-right: 5px; }
#footer ul.quick-links li:hover a i { font-weight: 700; }
</style>
<script>
import Logo from '../common/Logo'
import Loader from './Loader'
export default {
  components: {
    'Logo': Logo,
    'Loader': Loader
  }
}
</script>