import Vue from 'vue'
import VueRouter from 'vue-router'
import Axios from 'axios'

Vue.use(VueRouter)
if (process.env.NODE_ENV === 'development') {
  Axios.defaults.baseURL = 'http://127.0.0.1:5000/api/'
} else {
  Axios.defaults.baseURL = 'http://api.careercraft.ie/api/'
}
if (localStorage.getItem('accessToken') != 'undefined') {
  Axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('accessToken')
}

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { 
      title: 'Interview Coaching | Job Application Services | Psychometric Assessment Services',
      dynamicParam: '-',
      metaTags: [{
          name: 'description',
          content: 'Providing interview coaching services, cv writing services, career coaching and job application services for job-seekers. Providing executive coaching and psychometric assessment services for employers'
        }, {
          property: 'og:description',
          content: 'Providing interview coaching services, cv writing services, career coaching and job application services for job-seekers. Providing executive coaching and psychometric assessment services for employers'
        }
      ],
      layout: 'default' 
    },
    component: require('@/views/Home.vue').default
  }, {
    path: '/about',
    name: 'about',
    meta: { 
      title: 'About Me: Interview Coaching and Job Application Specialist',
      dynamicParam: '-',
      metaTags: [
        {
          name: 'description',
          content: 'I am an organisational psychologist specialising in job interview coaching and job applications. Interview coaching can be delivered face-to-face in Dublin, Sligo and Cork or remotely online'
        }, {
          property: 'og:description',
          content: 'I am an organisational psychologist specialising in job interview coaching and job applications. Interview coaching can be delivered face-to-face in Dublin, Sligo and Cork or remotely online'
        }
      ],
      layout: 'default' 
    },
    component: require('@/views/AboutUs.vue').default
  }, {
    path: '/interview-coaching',
    name: 'interview',
    meta: { 
      title: 'Interview Coaching',
      dynamicParam: '-',
      metaTags: [
        {
          name: 'description',
          content: 'Whether you feel completely unprepared for interview or your interview skills just need a polish, this interview preparation service will help you to get that job'
        }, {
          property: 'og:description',
          content: 'Whether you feel completely unprepared for interview or your interview skills just need a polish, this interview preparation service will help you to get that job'
        }
      ],
      layout: 'default' 
    },
    component: require('@/views/Interview.vue').default
  }, {
    path: '/cv-writing',
    name: 'cvWriting',
    meta: { 
      title: 'CV Preparation / Resume Preparation',
      dynamicParam: '-',
      metaTags: [
        {
          name: 'description',
          content: 'This CV writing service will teach you everything you need to know about the CV format, content and style you need to get that interview'
        }, {
          property: 'og:description',
          content: 'This CV writing service will teach you everything you need to know about the CV format, content and style you need to get that interview'
        }
      ],
      layout: 'default' 
    },
    component: require('@/views/CVWriting.vue').default
  }, {
    path: '/career-coaching',
    name: 'careerCoaching',
    meta: { 
      title: 'Career Coach',
      dynamicParam: '-',
      metaTags: [
        {
          name: 'description',
          content: 'This online career coaching service will provide you with the career help you need to make the right decisions and take the right action'
        }, {
          property: 'og:description',
          content: 'This online career coaching service will provide you with the career help you need to make the right decisions and take the right action'
        }
      ],
      layout: 'default' 
    },
    component: require('@/views/CareerCoaching.vue').default
  }, {
    path: '/psychometric-testing',
    name: 'Psychometric Testing',
    meta: { 
      title: 'Psychometric Testing',
      dynamicParam: '-',
      metaTags: [
        {
          name: 'description',
          content: 'If you want to know whether you’re hiring the right person or how to spot the best candidate you may want to consider psychometric testing. It is the best way of predicting who will succeed on the job and who will struggle'
        }, {
          property: 'og:description',
          content: 'If you want to know whether you’re hiring the right person or how to spot the best candidate you may want to consider psychometric testing. It is the best way of predicting who will succeed on the job and who will struggle'
        }
      ],
      layout: 'default' 
    },
    component: require('@/views/PsychometricTesting.vue').default
  }, {
    path: '/executive-coaching',
    name: 'Executive Coaching',
    meta: { 
      title: 'Executive Coaching',
      dynamicParam: '-',
      metaTags: [
        {
          name: 'description',
          content: 'Executive coaching has been proven to dramatically improve employee performance and satisfaction in the workplace'
        }, {
          property: 'og:description',
          content: 'Executive coaching has been proven to dramatically improve employee performance and satisfaction in the workplace'
        }
      ],
      layout: 'default' 
    },
    component: require('@/views/ExecutiveCoaching.vue').default
  }, {
    path: '/faq',
    name: 'faq',
    meta: { 
      title: 'Frequently Asked Questions | Career Craft',
      dynamicParam: '-',
      metaTags: [
        {
          name: 'description',
          content: ''
        }, {
          property: 'og:description',
          content: ''
        }
      ],
      layout: 'default' 
    },
    component: require('@/views/FAQ.vue').default
  }, {
    path: '/contact',
    name: 'contactUs',
    meta: { 
      title: 'Contact Us | Career Craft',
      dynamicParam: '-',
      metaTags: [
        {
          name: 'description',
          content: 'If you’d like to book an appointment for interview coaching or a different career service, or just to have a chat and discuss your career needs, please get in touch'
        }, {
          property: 'og:description',
          content: 'If you’d like to book an appointment for interview coaching or a different career service, or just to have a chat and discuss your career needs, please get in touch'
        }
      ],
      layout: 'default' 
    },
    component: require('@/views/ContactUs.vue').default
  }, {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    meta: { 
      title: 'Privacy Policy | Career Craft',
      dynamicParam: '-',
      metaTags: [
        {
          name: 'description',
          content: ''
        }, {
          property: 'og:description',
          content: ''
        }
      ],
      layout: 'default' 
    },
    component: require('@/views/PrivacyPolicy.vue').default
  }, {
    path: '/terms-and-condition',
    name: 'termsAndCondition',
    meta: { 
      title: 'Terms And Condition | Career Craft',
      dynamicParam: '-',
      metaTags: [
        {
          name: 'description',
          content: ''
        }, {
          property: 'og:description',
          content: ''
        }
      ],
      layout: 'default' 
    },
    component: require('@/views/TermsAndCondition.vue').default
  }, {
    path: '*',
    name: 'pageNotFound',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 };
  },
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // window.console.log(to.path)
  // if (localStorage.getItem('roleID') === null && to.path != '/login') {
  //   window.location.href = '/login'
  // }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title && r.meta.dynamicParam)
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    if (nearestWithTitle.meta.dynamicParam == undefined || nearestWithTitle.meta.dynamicParam == '-') {
      document.title = nearestWithTitle.meta.title
    } else {
      const pageTitle = nearestWithTitle.meta.title.replace(':' + nearestWithTitle.meta.dynamicParam, to.params[nearestWithTitle.meta.dynamicParam].replace(/[^a-zA-Z0-9]/g,' ').replace(/\b[a-z]/g, (x) => x.toUpperCase()))
      document.title = pageTitle
    }
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag))
  next()
})

export default router
