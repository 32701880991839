<template>
  <div id="app">
      <component :is="layout">
        <router-view/>
      </component>
  </div>
</template>
<style>
#app { font-family: 'Montserrat', 'Helvetica', "Arial", sans-serif;  -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; text-align: center; color: #333333; }
p, small { font-family: "Spartan", "Times New Roman", serif; text-align: justify; }
.close { outline: none !important; }
.cursor-pointer { cursor: pointer; }
.container-fluid { padding: 0px;}
h1, h2, h3, h4, h5, h6 { font-family: 'Montserrat'; color: #424746; }
.logo-first, .logo-second { font-size: 25px; font-family: 'Montserrat'; }
.logo-first { color: #9DE3CD; }
.logo-second { color: #424746; }
.tagline {font-size: 11px;}
.bg-alternate { background-color: #D5E1DF; }
.btn-w150 { width: 150px; }
.fs-12 { font-size: 12px; }
.fs-16 { font-size: 16px !important; }
.active { border-bottom: 1px solid #72827F; color: #72827F; }
.active a {color: #72827F !important; }
.section-border { border-top: 5px solid #72827F; margin-top: 20px; width: 15%; }
.input-group-prepend { position: absolute; float: right; right: 0; top: 5px; z-index: 100; }
.input-group-text { background: none !important; border: none !important; }  
.card-header, .card-footer { background: transparent !important; border: none !important; }
.pagination .disabled a { background-color: #72827F !important; color: #fff !important; }
th { color: #72827F; }
.modal-footer { border: none !important; }
.page-heading { color: #9DE3CD; }
@keyframes shakeError { 0% { transform: translateX(0); } 15% { transform: translateX(0.375rem); } 30% { transform: translateX(-0.375rem); } 45% { transform: translateX(0.375rem); } 60% { transform: translateX(-0.375rem); } 75% { transform: translateX(0.375rem); } 90% { transform: translateX(-0.375rem); } 100% { transform: translateX(0); } }
.error { margin-top: 5px; display: block; text-align: left; padding-left: 14px; font-size: 0.85rem ; line-height: 1; color: #f57f6c; animation-name: shakeError; animation-fill-mode: forwards; animation-duration: .6s; animation-timing-function: ease-in-out; }
</style>
<script>
const defaultLayout = 'default'
export default {
  computed: {
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  },
  created () {
    // Check if the browser supports notification
    // if ('Notification' in window) {
    //   Notification.requestPermission() // Ask the user for permission
    // }
  }
}
</script>