import { render, staticRenderFns } from "./PrivacyPolicy.vue?vue&type=template&id=73f2acf0&scoped=true&"
var script = {}
import style0 from "./PrivacyPolicy.vue?vue&type=style&index=0&id=73f2acf0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73f2acf0",
  null
  
)

export default component.exports