<template>
  <!-- Loader starts -->
    <div class="career-craft-loader" v-if="loader == true">
      <div class="loader">Loading...</div>
    </div>
  <!-- Loader ends -->
</template>
<style scoped>
.career-craft-loader { position:fixed; left:0; top:0; z-index:99999; background-color:#f6f6f7; width:100%; height:100%; display:block; }
.loader { width: 250px; height: 50px; line-height: 50px; text-align: center; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-family: helvetica, arial, sans-serif; text-transform: uppercase; font-weight: 900; color: #7C7C7B; letter-spacing: 0.2em; }
.loader::before, .loader::after { content: ""; display: block; width: 15px; height: 15px; background: #7C7C7B; position: absolute; animation: load 0.7s infinite alternate ease-in-out; }
.loader::before { top: 0; }
.loader::after { bottom: 0; }
 @keyframes load {
  0% {
    left: 0;
    height: 30px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 40px;
	}
  100% {
    left: 235px;
    height: 30px;
    width: 15px;
	}
}
</style>
<script>
import { Bus } from '../../main'
export default {
  data () {
    return {
      loader: false
    }
  },
  created () {
    Bus.$on('loader', (data) => {
      // window.console.log(data)
      this.loader = data // toggle loader
    })
  }
}
</script>